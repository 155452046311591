
import Vue from "vue";
// @ts-ignore
import Dialog from "@/components/Content/Dialogs/Default.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardAction.vue";

export default Vue.extend({
	name: "ConfirmDeleteEntity",
	props: {
        open:{
            type: Boolean,
            default: false
        },
		err:{
            type: Boolean,
            default: false
        },
        id:{
            type: Number,
            default: null,
        },
        entity:{
            type: String,
            default: "",
        },
		msg:{
            type: String,
            default: "",
        },
		sub_msg:{
            type: String,
            default: "",
        },
    },
    components:{
        Dialog,
        CardActions
    },
    data: () => ({}),
    created(){},
    mounted(){},
    computed:{
        getText(){
            return this.msg;
        },
		getSubText(){
            return this.sub_msg;
        },
		isDisabled(){
			return {submit: this.err}
		}
    },
    methods:{
        handleAction(event: { type: string }) {
			const { type } = event;

			switch (type) {
				case "submit":
                    this.handleActions();
					break;
				case "cancel":
                    this.handleCancel();
					break;
			}
		},

        async handleCancel(){
            this.$emit("handler-cancel-delete");
        },

		async handleActions(){
            this.$emit("handler-delete", this.id);
        }
    }
});
