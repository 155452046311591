
import { isEmpty } from "lodash";
import Vue from "vue";
import Filterable from "../../../../components/Header/Tables/Filterable.vue";
import { SortingOption } from "../../../../interfaces/paginated";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";

export default Vue.extend({
	name: "TableList",
	props: {
		current_page: {
			type: Number,
		},
		next_page_url: {
			type: String,
		},
		path: {
			type: String,
		},
		per_page: {
			type: Number,
		},
		prev_page_url: {
			type: String,
		},
		to: {
			type: Number,
		},
		total: {
			type: Number,
		},
		headers: {
			type: Array,
			default: [],
		},
		items: {
			type: Array,
			default: [],
		},
		options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: { Filterable, IconBase },
	data: function () {
		return {};
	},
	created() {},
	mounted() {},

	computed: {
		currentPage: {
			set(val) {
				this.$emit("update-current-page", val);
			},
			get() {
				return this.current_page;
			},
		},
		getLength() {
			return Math.ceil(this.total / this.per_page);
		},
		perPage() {
			return !isNaN(this.per_page) && isEmpty(this.per_page)
				? this.per_page
				: 25;
		},
	},

	methods: {
		getColor(active: Boolean) {
			return active ? "green--text" : "red--text";
		},
		getActiveText(active: Boolean) {
			return active ? "Active" : "Inactive";
		},
		updatePaginate(data: Number) {
			this.$emit("update-paginate", data);
		},
		selectedOption(params: { options: SortingOption; filter: any }) {
			this.$emit("selected-option", {
				options: params.options,
				filter: params.filter,
			});
		},
		removeOption() {
			this.$emit("remove-option");
		},

		rowClick(item: any, slot: any, type: any, event: any) {

			if (event && event.event) {
				event?.event?.preventDefault();
				event?.event?.stopPropagation();
			}

			switch (type) {
				case "line":
					event?.stopPropagation();
					break;
				case "row":
					// @ts-ignore
					this.$router.push({
						name: "CreativeEdit",
						params: { id: item.id },
					});
					break;
				case "duplicated":
					this.$emit("open-dialog", {id: item.id, external_id: item.external_id});
					event?.stopPropagation();
					break;
				case "activityLog":
					this.$router.push({
						name:"ActivityLogCreative" ,
						query: { id: item.id }
					});
					//this.setDataActivityLog({id: item.id, entity: "campaign"});
					event?.stopPropagation();
					break;
			}
		},
	},
});
